import React, {Component} from "react";
import Rete from "rete";
import TextField from '@material-ui/core/TextField';
import './OptionControl.css'

export default class OptionControl extends Rete.Control {
    static component = ({ value, onChange, options }) => (
        <select className="select-css" value={value} onChange={onChange}>
            <option hidden disabled value="0"> -- select an option -- </option>
            {options.map(option =>( <option value={option}>{option}</option>))}
        </select>
    );
  
    constructor(emitter, key, node, readonly = false) {
      super(key);
      this.emitter = emitter;
      this.key = key;
      this.component = OptionControl.component;
  
      const initial = node.data[key] || 0;
      node.data[key] = initial;
      this.props = {
        readonly,
        options: node.data['options'] ||  [],
        value: initial,
        onChange: v => {
          this.setValue(v.target.value);
        }
      };
    }
  
    setValue(val) {
      console.log(val, 'option change?')
      this.props.value = val;
      this.putData(this.key, val);
      this.update();
    }
  }
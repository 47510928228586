// in src/App.js
import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser, fetchUtils } from 'react-admin';
import authProvider from './authProvider';
import hasuraDataProvider from 'ra-data-hasura';
import {AutomationList,AutomationEdit,AutomationShow} from './automations'
import { AutomationResultList, AutomationResultShow} from  './automationResults'

import { ApolloProvider } from '@apollo/react-hooks';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory';

const wsLink = new WebSocketLink({
  uri: 'wss://puppet-hasura.herokuapp.com/v1/graphql',
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'x-hasura-admin-secret':'Nice4667428!!'
      }
   }
  },
});

// Create an http link:
const httpLink = new HttpLink({
  uri: 'https://puppet-hasura.herokuapp.com/v1/graphql'
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  const token  = localStorage.getItem('puppet_token')
  options.headers.set('Authorization', `Bearer ${token}`);
  // options.headers.set('x-hasura-admin-secret','Nice4667428!!')
  return fetchUtils.fetchJson(url, options);
};

const cache = new InMemoryCache();
const client = new ApolloClient({ link, cache });

const App = () => (
  <ApolloProvider client={client}>
    <Admin authProvider={authProvider} dataProvider={hasuraDataProvider('https://puppet-hasura.herokuapp.com', httpClient)}>
        <Resource name="automations" list={AutomationList} show={AutomationShow} edit={AutomationEdit} />
        <Resource name="automation_results" list={AutomationResultList} show={AutomationResultShow} />
      </Admin>
  </ApolloProvider>
      
  );
export default App;
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const AutomationDynamicInputMapping = ({dynamicInputs, runAutomation, id, setId, dataRows, setDataRows }) =>  {
    const classes = useStyles();
    

    const updateField = (event, id, field) => {
        const nd = [...dataRows]
        const i = nd.findIndex(d => d.id === id)
        nd[i][field] = event.target.value
        setDataRows(nd)
        // setInputMap({...inputMap, [field]: event.target.value })
    }
    
    const addRow = ()  => {
        
        setDataRows([...dataRows, { id: id + 1 }])
        setId(id + 1 )
    }

    const deleteRow =  (id) => {
        setDataRows(dataRows.filter(dr => dr.id !== id))
    }
    console.log(dynamicInputs, 'rows?')
    return (
        <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow> 
                {dynamicInputs.map(d => (<TableCell key={d.field}>{d.field}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.map((row,i) => (
              <TableRow key={row.id}>
                   {dynamicInputs.map(d => 
                    (<TableCell key={`row-${d.field}`} >
                        <TextField id="standard-basic" variant="filled" value={row[d.field]} onChange={(e) => updateField(e, row.id, d.field)}  label={`Enter ${d.field}`} />
                    </TableCell>
                    ))}
                    <TableCell>
                    <IconButton onClick={() => deleteRow(row.id)} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
            onClick={addRow}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            >
                Add Row
            </Button>
      </>
    );
  }
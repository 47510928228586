import React, {Component} from "react";
import Rete from "rete";
import TextField from '@material-ui/core/TextField';
export default class TextControl extends Rete.Control {
    static component = ({ value, onChange }) => (
        <input type="test" value={value} onChange={onChange}/>
    );
  
    constructor(emitter, key, node, readonly = false) {
      super(key);
      this.emitter = emitter;
      this.key = key;
      this.component = TextControl.component;
  
      const initial = node.data[key] || 0;
  
      node.data[key] = initial;
      this.props = {
        readonly,
        value: initial,
        onChange: v => {
          this.setValue(v);
          this.emitter.trigger("process");
        }
      };
    }
  
    setValue(val) {
      this.props.value = val;
      this.putData(this.key, val);
      this.update();
    }
  }
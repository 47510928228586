// in src/App.js
import React, { useState, useEffect } from 'react';
import { useDataProvider, List, Datagrid, TextField, DateField, Show, SimpleShowLayout, ReferenceField, fetchUtils } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Slideshow from '../components/Slideshow';

export const AutomationResultList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="result.state" />
            <DateField source="updated_at" />
            <DateField source="created_at" />
            <TextField source="id" />
            <ReferenceField source="automation_id" reference="automations"><TextField source="id" /></ReferenceField>

        </Datagrid>
    </List>
);

export const AutomationResultShow = (record) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [automationResult, setAR] = useState({})

    useEffect(() => {
        dataProvider.getOne('automation_results', { id: record.id })
            .then(({ data }) => {
                setLoading(true);
                setAR(data);
                console.log(data, 'AR DATA??')
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider, record]);
    
    const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true,
        onChange: (oldIndex, newIndex) => {
          console.log(`slide transition from ${oldIndex} to ${newIndex}`);
        }
      }

    return(
        <Show {...record}>
            <SimpleShowLayout>
                <Typography color="textSecondary" gutterBottom>
                Batch: {automationResult.batch_id}
                </Typography>
                {automationResult.result && <Slideshow objects={automationResult.result.actionMeta}/>}
            </SimpleShowLayout>
         
        </Show>
    )
};
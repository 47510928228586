import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {AutomationDynamicInputMapping} from '../automations/automationDynamicInputMapping';
import {PaginationOptions} from '../automations/paginationOptions';
import CircularProgress from '@material-ui/core/CircularProgress';
import shortid from 'shortid'
import { CSVLink, CSVDownload } from "react-csv";
import CSVReader from 'react-csv-reader'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function InputMappingModal({automation, open, handleClose, runAutomationWithInputMappings, runAutomationsBulk, dynamicInputs }) {
    const [id, setId] = useState(1)
    const [dataRows, setDataRows] = useState([{ id: 1}])
    const [loading, setLoading] = useState(false)
    const [pageRange, setPageRange] = React.useState([1, 10]);

    const parseCsv = (data) => {
      console.log(data, 'csv data?')
      setDataRows(data.map((d,i) => ({id: i, ...d})))
    } 
    const classes = useStyles();
    const runAutomation = async () => {
        setLoading(true)
        const batchId = shortid.generate()

        if(dataRows.length > 5){
          await runAutomationsBulk(dataRows, batchId, pageRange)
        } else{
          await Promise.all(dataRows.map(async inputMap => {
            runAutomationWithInputMappings(inputMap, batchId, pageRange)
        }))
        }
        
        setLoading(false)
        handleClose()
    } 
 return (
    <div>
      <Dialog fullWidth={true}
        maxWidth={'lg'} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Setup Input Mappings
        </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>
          <Grid container spacing={3}>
           
            <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle1">Import via spreadsheet</Typography>
              <CSVLink filename={`${automation.title}-template.csv`} data={[dynamicInputs.map(d => d.field),dynamicInputs.map(d => '')]}>Download import template</CSVLink>
            </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <Typography variant="subtitle1">Upload Template Data</Typography>
                  <CSVReader parserOptions={{header:true}} onFileLoaded={parseCsv} />
                </Paper>
            </Grid>
           
          </Grid>
        </div>
       
        {automation.config && automation.config.pagination && <PaginationOptions automation={automation} pageRange={pageRange} setPageRange={setPageRange}/>}
         
        <AutomationDynamicInputMapping dataRows={dataRows} setDataRows={setDataRows} id={id} setId={setId} dynamicInputs={dynamicInputs} />
         
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} autoFocus onClick={runAutomation} color="primary">
            Run Automation
            {loading && <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { 
    SimpleShowLayout,
    Edit,
     Loading,
     SimpleForm,
     ArrayInput,
     TextInput,
     DateInput,
     List,
     Datagrid,
     TextField,
     ArrayField,
     DateField,
     ReferenceField,
     SimpleFormIterator,
     useDataProvider
    } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {AutomationResultsTable} from './automations/automationResultsTable';
import InputMappingModal from './modal/inputMappingModal'
import { createEditor, Diagram } from "./rete";
import { useMutation, useSubscription } from "@apollo/react-hooks";
// import Loading from '@material-ui/core/Loading';
  import { useGetOne, useGetManyReference } from 'react-admin';
import {runAutomation, runBulkAutomation } from  './services/api';
import gql from 'graphql-tag'
import Container from '@material-ui/core/Container';
import FullScreenModal from './components/FullScreenModal';

export const AutomationList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="script" />
            {/* <ArrayField source="actions"><SingleFieldList><ChipField source="action" /></SingleFieldList></ArrayField> */}
            <DateField source="updated_at" />
            <DateField source="created_at" />
        </Datagrid>
    </List>
);

export const AutomationEdit = props => (
   <>
   <Edit {...props}>
       
        <SimpleForm>
      
            <TextInput source="script" />
            <ArrayInput source="actions"><SimpleFormIterator><TextInput source="action" />
<TextInput source="value" />
<TextInput source="field" />
<TextInput source="selector" />
<TextInput source="type" /></SimpleFormIterator></ArrayInput>
            <DateInput source="updated_at" />
            <DateInput source="created_at" />
            <TextInput source="id" />
            <TextInput source="title" />
           
        </SimpleForm>
    </Edit></>
);

const useStyles = makeStyles({
    root: {
      minWidth: 275,

    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  
export const AutomationShow = (record) => {
    const dataProvider = useDataProvider();
    // const [automationResults, setAutomationResults] = useState([])
    const [automation, setAutomation] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const classes = useStyles();
    const nodeContainer = React.createRef();
    let editor = false
    const { data } = useSubscription(
        gql`
        subscription {
            automation_results(where:{automation_id:{_eq:${record.id}}},order_by:{updated_at:desc}) {
              id
              result
              input_map
              batch_id
              updated_at
            }
          }
        `
      );
    useEffect( () => {
      console.log(editor, 'what is editor?')
      if(nodeContainer.current && !editor) {
        console.log('creatinge dito')
        editor = true
        editor = createEditor(nodeContainer.current)
        console.log(editor, 'editor?')
      }
        
    }, [nodeContainer])
    useEffect(() => {
        dataProvider.getOne('automations', { id: record.id })
            .then(({ data }) => {
                setAutomation(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    // const { data, loading, error } = useGetOne('automations', record.id);
    // const { data: automationResults } = useGetManyReference('automation_results', 
    //     'automation_id',
    //      record.id, null,
    //    { field: 'created_at', order: 'DESC' }
    // )
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    let dynamicInputs = automation.actions.filter(a => a.action.indexOf('dynamic') !== -1)

    const runAutomationWithInputMappings = async (inputMap, batchId, pageRange) => {
      const result = await runAutomation(record.id, inputMap, batchId, pageRange)
      console.log(result,'auto result input mpa?',inputMap )
    }

    const runAutomationsBulk = async (inputMaps, batchId, pageRange) => {
      const result = await runBulkAutomation(record.id, inputMaps, batchId, pageRange)
      console.log(result, 'bulk runresult')
    }

    const closeModal = () => {
      setModalOpen(false)
    }

  const run = async () =>  {
    if(dynamicInputs) {
      setModalOpen(true)
    } else {
      const result = await runAutomation(record.id)
      console.log(result,  'result?')
    }
      
  }

  const replayAutomation = (row) =>  {
    console.log(row, 'replay this?')
  }

  return (
      <Box>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Automation
                </Typography>
                <Typography variant="h5" component="h2">
                {automation.title}
                </Typography>
               
            </CardContent>
            <CardActions>
            <Button disabled={loading} onClick={run} variant="contained" color="primary">
                Run Automation
            </Button>
            <FullScreenModal automation={automation}/>
            </CardActions>
        </Card>
        <InputMappingModal automation={automation} dynamicInputs={dynamicInputs} runAutomationWithInputMappings={runAutomationWithInputMappings} runAutomationsBulk={runAutomationsBulk} open={modalOpen} handleClose={closeModal}/>
        {data && <AutomationResultsTable replayAutomation={replayAutomation} rows={data.automation_results}  automation={automation} />}
        
      </Box>
    
  );
};
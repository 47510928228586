import axios from 'axios'
const BASE_URL = 'https://kontrol-puppet-server.herokuapp.com' || 'http://localhost:3333'

export default {
    login: async ({username,password}) => {
        
        const { data: { user } } = await axios.post(`${BASE_URL}/login`, { email: username, password })
        if(user) {
            console.log(user, 'user??')
            localStorage.setItem('puppet_token', user.token)
            localStorage.setItem('puppet_uid', user.id)
            const extensionId = "kcjkkhloagmpjllcnoldicpoaefaibhc"
            // eslint-disable-next-line
            if(chrome) {
                // eslint-disable-next-line
                chrome.runtime.sendMessage(extensionId, { type: 'credentials', user },
                function(response) {
                  if (!response.success)
                    console.log('failed to pass credentials to CE');
                });
            }
           
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
    },
    logout: () => {
        localStorage.removeItem('puppet_token');
        return Promise.resolve();
    },  
    checkAuth: params => { 
        return localStorage.getItem('puppet_token')
        ? Promise.resolve()
        : Promise.reject();
    },
    checkError: error => Promise.resolve(),
    getPermissions: params => Promise.resolve(),
};
import React, {useState} from 'react';
import PaginationSlider from './paginationSlider';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
      marginBottom: 20
  }
}));
export const PaginationOptions = ({pageRange, setPageRange }) => {
    const classes = useStyles();

    return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography className={classes.title} variant="h5"  >
                What pages should we visit?
            </Typography>
            <Paper className={classes.paper}>
                <PaginationSlider value={pageRange} setValue={setPageRange} />
            </Paper>
        </Grid>
       
    </Grid>)
}
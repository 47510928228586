import React from "react";
import { Node, Socket, Control } from "rete-react-render-plugin";

export class MyNode extends Node {
  render() {
    const { node, bindSocket, bindControl } = this.props;
    const { outputs, controls, inputs, selected } = this.state;
    
    return (
      <div className={`node ${selected}`} style={{ border: '5px solid #33a3cf',background: "#5cb6d9", width: '300px' }}>
        <div className="title" style={{ fontSize: 24, fontWeight: 800 }}>
          {node.data.action}
        </div>
        {node.data.field && <div className="title" style={{ fontSize: 20, fontWeight: 600 }}>
           Field: {node.data.field}
        </div>}
        {/* <div className="title">
           {node.data.selector}
        </div> */}
        {node.data.dataUrl && <img src={node.data.dataUrl} style={{ objectFit:'contain', marginLeft: '50px', width:'200px', height: '200px' }}/>}
        {/* Outputs */}
        {outputs.map(output => (
          <div className="output" key={output.key}>
            <div className="output-title"  style={{fontWeight: 600, fontSize: 16 }}>{output.name}</div>
            <Socket
              type="output"
              socket={output.socket}
              io={output}
              innerRef={bindSocket}
            />
          </div>
        ))}
        {/* Controls */}
        {controls.map(control => (
          <Control
            className="control"
            key={control.key}
            control={control}
            innerRef={bindControl}
          />
        ))}
        {/* Inputs */}
        {inputs.map(input => (
          <div className="input" key={input.key}>
            <Socket
              type="input"
              socket={input.socket}
              io={input}
              innerRef={bindSocket}
            />
            {!input.showControl() && (
              <div className="input-title" style={{fontWeight: 600, fontSize: 16 }}>{input.name}</div>
            )}
            {input.showControl() && (
              <Control
                className="input-control"
                control={input.control}
                innerRef={bindControl}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

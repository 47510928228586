import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';
import Chip from '@material-ui/core/Chip';
import { CSVLink, CSVDownload } from "react-csv";
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'lodash'
import levenshtein from 'js-levenshtein'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const createFilterMap = (automation) => {
  if(!automation.flow) return {}

  const filters = Object.values(automation.flow.nodes).filter(n => n.name === 'Filter')
  
  const filterMap = {}
  filters.map(({data, ...filter}) => {
    if(data.filter) {
      // find out what we are filtering
      const connections = filter.inputs.in.connections

      if(connections.length > 0) {
        connections.map(conn => {
          const node = automation.flow.nodes[conn.node]
          filterMap[node.data.field] = data.filter
        })
      }
    }
  })

  return filterMap
}

const generateData = (data, automation) => {
  const filterMap = createFilterMap(automation)

  const rows = []

  for(var i = 0; i< data.length; i++) {
    const d = data[i]
    const extractMap = d.result.extractMap
    const rays = []

    for(let key in extractMap) {
      if(Array.isArray(extractMap[key])) {
        rays.push(extractMap[key])        
      } 
    }
    
    if(rays.length > 0) {
      rows.push(..._.zip(...rays).map(v => ({...d.input_map,..._.zipObject(Object.keys(extractMap), v)})))
    } else {
      rows.push({...d.input_map, ...d.result.extractMap})
    }
    
  }
  return filterData(rows, filterMap)
}

const filterData = (rows, filterMap) => {
  console.log(filterMap, 'filterMap')
  return rows.filter(row => {
    for (let [key, value] of Object.entries(filterMap)) {
      if(row.hasOwnProperty(key)) {
        // console.log(row[key].toLowerCase(), row[value].toLowerCase(), levenshtein(row[key].toLowerCase(), row[value].toLowerCase(), 'levi'))
        if(row[key].trim().length < 1 ) {
          return false
        }
        if(levenshtein(row[key].toLowerCase(), row[value].toLowerCase()) > 5) {
          return false
        }
      }
    }
    return true
  })
}

export const AutomationResultsTable = ({rows, replayAutomation, automation }) =>  {
    const classes = useStyles();
    console.log(_.groupBy(rows,'batch_id'), 'rows groupby?')
    const finalRows = []
    const pRows = _.groupBy(rows,'batch_id')
    Object.keys(pRows).map(key => {
      finalRows.push({
        id: key,
        result: {
          success: pRows[key].filter(d => d.result.state === 'complete').length,
          fail: pRows[key].filter(d => d.result.state === 'error').length,
          pending: pRows[key].filter(d => d.result.state === 'pending').length,
          progress: _.meanBy(pRows[key], (d) => d.result.progress)
        },
        
        total:  pRows[key].length,
        extractedData: generateData(pRows[key], automation)
      })
    })
    
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">Result</TableCell>
              <TableCell align="right">Progress</TableCell>
              <TableCell align="right">Extract Map</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalRows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">
                  <Chip label={`Successful: ${row.result.success} / ${row.total}`} /><br/>
                  <Chip label={`Pending: ${row.result.pending} / ${row.total}`} /><br/>
                  <Chip label={`Failed: ${row.result.fail} / ${row.total}`} /><br/>
                 
                </TableCell>
                <TableCell>
                  <LinearProgress variant="determinate" value={row.result.progress || 0} />
                </TableCell>
              <TableCell align="right">
                {/* {JSON.stringify(row.extractedData)} */}
                <CSVLink data={row.extractedData}>Download Extracted Data</CSVLink>
              </TableCell>
             
                <TableCell>
                  <IconButton onClick={() => replayAutomation(row)} color="primary" aria-label="rerun this automation">
                    <ReplayIcon  />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
import axios from 'axios'
const BASE_URL = 'https://kontrol-puppet-server.herokuapp.com' || 'http://localhost:3333'
const headers = () =>  ({ Authorization: `Bearer ${localStorage.getItem('puppet_token')}`})
const handleError = (err) => {
    localStorage.removeItem('puppet_token');
    // window.location = '/login'
}
export const runAutomation = (id, inputMap = {}, batchId, pageRange) => {
    return axios({url: `${BASE_URL}/automations/${id}/run`, data: { inputMap, batchId, pageRange }, method: 'post', headers: headers()}).catch(handleError)
}

export const runBulkAutomation = (id, inputMaps, batchId, pageRange ) => {
    return axios({url: `${BASE_URL}/automations/${id}/runBulk`, data: { inputs: inputMaps, batchId, pageRange }, method: 'post', headers: headers()}).catch(handleError)
}
import React, {Component} from "react";
import Rete from "rete";
import ReactRenderPlugin from "rete-react-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import { MyNode } from "./MyNode";
import TextControl from './controls/TextControl'
import OptionControl from './controls/OptionControl'
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import ContextMenuPlugin, { Menu, Item, Search } from 'rete-context-menu-plugin';


var numSocket = new Rete.Socket("Number value");
const strSocket = new Rete.Socket('String');
const filterSocket = new Rete.Socket('Filter');

class NumControl extends Rete.Control {
  static component = ({ value, onChange }) => (
    <input
      type="number"
      value={value}
      ref={ref => {
        ref && ref.addEventListener("pointerdown", e => e.stopPropagation());
      }}
      onChange={e => onChange(+e.target.value)}
    />
  );

  constructor(emitter, key, node, readonly = false) {
    super(key);
    this.emitter = emitter;
    this.key = key;
    this.component = NumControl.component;

    const initial = node.data[key] || 0;

    node.data[key] = initial;
    this.props = {
      readonly,
      value: initial,
      onChange: v => {
        this.setValue(v);
        this.emitter.trigger("process");
      }
    };
  }

  setValue(val) {
    this.props.value = val;
    this.putData(this.key, val);
    this.update();
  }
}

class NumComponent extends Rete.Component {
  constructor() {
    super("Number");
  }

  builder(node) {
    var out1 = new Rete.Output("num", "Number", numSocket);
    var ctrl = new NumControl(this.editor, "num", node);

    return node.addControl(ctrl).addOutput(out1);
  }

  worker(node, inputs, outputs) {
    outputs["num"] = node.data.num;
  }
}

class ActionComponent extends Rete.Component {
  constructor() {
    super("Action");
    this.data.component = MyNode; // optional
  }

  builder(node) {
    var inp1 = new Rete.Input("in", "In", strSocket);
    
    var out = new Rete.Output("out", "Out", strSocket);
    var filter = new Rete.Output("filter", "Filter", filterSocket);

    var ctrl = new TextControl(this.editor, "filter", node);

    // inp1.addControl(new NumControl(this.editor, "num1", node));
    // inp2.addControl(new NumControl(this.editor, "num2", node));
    
    node
      .addInput(inp1)
      .addOutput(out)
      // .addOutput(filter)
      if(node.data.action.indexOf('extract') !== -1) {
        node.addOutput(filter)
      }
    return node
  }

  worker(node, inputs, outputs) {
    var n1 = inputs["num1"].length ? inputs["num1"][0] : node.data.num1;
    var n2 = inputs["num2"].length ? inputs["num2"][0] : node.data.num2;
    var sum = n1 + n2;

    this.editor.nodes
      .find(n => n.id == node.id)
      .controls.get("preview")
      .setValue(sum);
    outputs["num"] = sum;
  }
}

export default class FilterComponent extends Rete.Component {
  constructor({ options }) {
    super("Filter");
    this.data.options = options
    // this.data.component = FilterNode; // optional
  }

  builder(node) {
    var inp1 = new Rete.Input("in", "In", filterSocket);
    
    var out = new Rete.Output("out", "Out", strSocket);
    node.data.options =  this.data.options
    var ctrl = new OptionControl(this.editor, "filter", node);

    return node
      .addInput(inp1)
      .addControl(ctrl)
      .addOutput(out);
  }

  worker(node, inputs, outputs) {

  }
}

export class Diagram extends Component {
    constructor() {
        super()
        this.state = {
            automation: {}
        }
    }

    async componentDidMount() {
      const {automation}= this.props
      this.editor = await createEditor(this.ref, automation, this.props.setEditor)
      let prev
      if(automation.flow) {
        console.log(automation.flow, 'flow?')
        this.editor.fromJSON(automation.flow)
      } else {
        await Promise.all(automation.actions.map(async action => {
    
          var node = await (new ActionComponent()).createNode(action);
          
          this.editor.addNode(node);
          
          if(prev) {
              this.editor.connect(prev.outputs.get("out"), node.inputs.get("in"));
          }
          this.editor.trigger('arrange', {node});
        
          prev = node
      }))
      }
      
      this.editor.view.resize();
      AreaPlugin.zoomAt(this.editor, this.editor.nodes);
       
    }
    
   

    render() {
        return  <div
        style={{ width: "100%", height: "100%" }}
        ref={ref => this.ref=ref}
      />
    }
}

export async function createEditor(container, automation, setEditor) {
  const inputVariables = automation.actions.filter(a => a.action === "input:dynamic").map(a => a.field )
  var components = [new FilterComponent({ options: inputVariables }), new ActionComponent()];
  
  var editor = new Rete.NodeEditor("demo@0.1.0", container);
  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin);
    editor.use(AreaPlugin, {
      background: false,
      snap: false,
      scaleExtent: { min: 0.1, max: 1 },
      translateExtent: { width: 5000, height: 4000 }
  })

  editor.use(AutoArrangePlugin, { margin: {x: 50, y: 50 }, depth: 5 });
  editor.use(ContextMenuPlugin, {
    searchBar: false, // true by default
    searchKeep: title => true, // leave item when searching, optional. For example, title => ['Refresh'].includes(title)
    delay: 100,
    allocate(component) {
        return ['Add Node'];
    },
    rename(component) {
        return component.name;
    },
    items: {
       
    },
    nodeItems: {
        'Click me'(){ console.log('Works for node!') },
        'Delete': true, // don't show Delete item
        'Clone': true // or Clone item
    },
  
  });
  var engine = new Rete.Engine("demo@0.1.0");

  components.map(c => {
    editor.register(c);
    engine.register(c);
  });

  editor.on(
    "process nodecreated noderemoved connectioncreated connectionremoved",
    async () => {
     setEditor(editor.toJSON())
    }
  );

  editor.view.resize();
  AreaPlugin.zoomAt(editor, editor.nodes);

  return editor
}
